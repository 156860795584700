import {config} from "~/utils/config.js";
import {omniFetch} from "~/utils/fetch.js";

export function homepageIndex() {
  return omniFetch({
    url: config.v1Path + config.homepageIndexPath,
    method: "get",
    params: {}
  })
}
