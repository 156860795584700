<script setup>
import {useBaseStore} from "~/stores/base";
import ModuleStreamerCoupon from "~/components/Streamer/ModuleStreamerCoupon.vue";
import ModuleStreamerWaitlist from "~/components/Streamer/ModuleStreamerWaitlist.vue";
import {getStreamerStatusLocalStorage, setStreamerStatusLocalStorage} from "~/utils/common";
import ModuleStreamerInvitation from "~/components/Streamer/ModuleStreamerInvitation.vue";

const STREAMERS = {
  HOME_PAGE_POP: "homePagePop",
  WAITLIST_POP: "waitlistPop",
  INVITATION_POP: "invitationPop",
}
const visibleStreamerName = ref("")
const visibleStreamerTitle = ref({})
const visibleStreamerSubtitle = ref({})
const visibleStreamerText = ref({})
const visibleStreamerPic = ref({})
const visibleStreamerBtnText = ref("")

const visibleStreamerDelay = ref(0)

const streamerMobile = ref(null)
const streamerDesktop = ref(null)
const placeholder = ref(null)

/** computed **/
const isMobile = computed(() => useBaseStore().getterIsMobile)
const appInit = computed(() => useBaseStore().getterAppInit)
const isLogin = computed(() => useBaseStore().getterIsLogin)
const userInfo = computed(() => useBaseStore().getterUserInfo)

watch(isMobile, () => {
  placeholder.value.style.height = '0px'  // 复原占位符高度为0
//   animateSlideDown()
})

const streamerInit = () => {
  for (let i = 0; i < appInit.value.popInfo.length; i++) {
    const item = appInit.value.popInfo[i]

    // 本地存储的显示状态
    let isStreamerVisible = getStreamerStatusLocalStorage(item.name, {
      maxShowUpTimes: item.popCount,
      intervalTime: item.intervalTime
    })
    console.log(isStreamerVisible)

    // 每个横幅的额外显示判断逻辑
    switch (item.name) {
      case STREAMERS.HOME_PAGE_POP:
        // 已登录且（已订阅或已暂停） 不显示
        if (isLogin.value && (userInfo.value.subStatus === SUB_STATUS.SUBSCRIBED || userInfo.value.subStatus === SUB_STATUS.PAUSED)) isStreamerVisible = false
        break;
      case STREAMERS.WAITLIST_POP:
        // 已登录 不显示
        if (isLogin.value) isStreamerVisible = false
        break;
      case STREAMERS.INVITATION_POP:
        // 已登录 且 是会员(订阅、暂停、取消) 不显示
        if (isLogin.value && userInfo.value.isMember) isStreamerVisible = false
        break;
      default:
        // 未定义的不显示
        isStreamerVisible = false
        break;
    }
    console.log("用户状态判断后")
    console.log(isStreamerVisible)

    if (isStreamerVisible) {
      visibleStreamerName.value = item.name
      visibleStreamerTitle.value = item.title
      visibleStreamerSubtitle.value = item.subTitle
      visibleStreamerText.value = item.details
      visibleStreamerDelay.value = item.delay // 秒
      visibleStreamerPic.value = item.img
      visibleStreamerBtnText.value = item.buttonTitle
      // 如果存在已显示的横幅，则退出循环
      return
    }
  }
}
const handleStreamerClose = () => {
  setStreamerStatusLocalStorage(visibleStreamerName.value)
  animateSlideUp()
}
const handleSuccess = () => {
  setStreamerStatusLocalStorage(visibleStreamerName.value, {hideForever: true})
}
const animateSlideDown = () => {
  let streamerHeight
  setTimeout(() => {
    if (isMobile.value) {
      streamerHeight = streamerMobile.value.offsetHeight;  // 获取横幅的实际高度
      streamerMobile.value.classList.add('slide-down');  // 添加动画类，触发滑出效果
    } else {
      streamerHeight = streamerDesktop.value.offsetHeight;  // 获取横幅的实际高度
      streamerDesktop.value.classList.add('slide-down');  // 添加动画类，触发滑出效果
    }
    placeholder.value.style.height = streamerHeight + 'px'  // 占位符高度填充，内容区被向下推
  }, visibleStreamerDelay.value * 1000)
}

const animateSlideUp = () => {
  if (isMobile.value) {
    streamerMobile.value.classList.remove('slide-down');
    streamerMobile.value.classList.add('slide-up');
  } else {
    streamerDesktop.value.classList.remove('slide-down');
    streamerDesktop.value.classList.add('slide-up');
  }
  placeholder.value.style.height = '0px'
}
onMounted(() => {
  streamerInit()
  animateSlideDown()
})
</script>

<template>
  <div class="streamer-mobile" ref="streamerMobile" v-if="isMobile">
    <!-- 优惠券横幅 -->
    <module-streamer-coupon
      v-if="visibleStreamerName === STREAMERS.HOME_PAGE_POP"
      :title="visibleStreamerTitle"
      :subtitle="visibleStreamerSubtitle"
      :tips="visibleStreamerText"
      @close="handleStreamerClose"
      @success="handleSuccess"
    />
    <!-- waitlist 横幅 -->
    <module-streamer-waitlist
      v-if="visibleStreamerName === STREAMERS.WAITLIST_POP"
      :title="visibleStreamerTitle"
      :subtitle="visibleStreamerSubtitle"
      :desc="visibleStreamerText"
      @close="handleStreamerClose"
      @success="handleSuccess"
    />
    <!-- 邀请横幅 -->
    <module-streamer-invitation
      v-if="visibleStreamerName === STREAMERS.INVITATION_POP"
      :title="visibleStreamerTitle"
      :subtitle="visibleStreamerSubtitle"
      :desc="visibleStreamerText"
      :pic="visibleStreamerPic"
      :btn-text="visibleStreamerBtnText"
      @close="handleStreamerClose"
      @success="handleSuccess"
    />
  </div>
  <div class="streamer-desktop" ref="streamerDesktop" v-else>
    <!-- 优惠券横幅 -->
    <module-streamer-coupon
      v-if="visibleStreamerName === STREAMERS.HOME_PAGE_POP"
      :title="visibleStreamerTitle"
      :subtitle="visibleStreamerSubtitle"
      :tips="visibleStreamerText"
      @close="handleStreamerClose"
      @success="handleSuccess"
    />
    <!-- waitlist 横幅 -->
    <module-streamer-waitlist
      v-if="visibleStreamerName === STREAMERS.WAITLIST_POP"
      :title="visibleStreamerTitle"
      :subtitle="visibleStreamerSubtitle"
      :desc="visibleStreamerText"
      @close="handleStreamerClose"
      @success="handleSuccess"
    />
    <!-- 邀请横幅 -->
    <module-streamer-invitation
      v-if="visibleStreamerName === STREAMERS.INVITATION_POP"
      :title="visibleStreamerTitle"
      :subtitle="visibleStreamerSubtitle"
      :desc="visibleStreamerText"
      :pic="visibleStreamerPic"
      :btn-text="visibleStreamerBtnText"
      @close="handleStreamerClose"
      @success="handleSuccess"
    />
  </div>
  <div class="placeholder" ref="placeholder"/>
</template>

<style scoped lang="scss">
@import "src/assets/config";

.streamer-mobile {
  width: 100%;
  position: absolute; /* 使横幅不占据页面布局空间 */
  transform: translateY(-100%);
}

.streamer-desktop {
  width: 100%;
  position: absolute; /* 使横幅不占据页面布局空间 */
  transform: translateY(-100%);
}

.placeholder {
  height: 0;
  transition: height 0.5s ease-out;
}

.slide-down {
  transition: transform 0.5s ease-out;
  animation: slideDown 0.5s ease-out forwards; /* 触发滑动动画 */
}

@keyframes slideDown {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

.slide-up {
  transition: transform 0.5s ease-out;
  animation: slideUp 0.5s ease-out forwards; /* 触发滑动动画 */
}

@keyframes slideUp {
  0% {
    transform: translateY(0);
  }
  99% {
    opacity: 1; // 解决收起后，页面顶部可以看到横幅的bug
  }
  100% {
    opacity: 0; // 解决收起后，页面顶部可以看到横幅的bug
    transform: translateY(-100%);
  }
}
</style>
