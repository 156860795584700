<script setup>
import {useBaseStore} from "~/stores/base";
import ModuleInput from "~/components/ModuleInput.vue";
import {pointReferralCodeCheck} from "~/api/api.point";
import {PATH_REGISTER, PATH_SUBSCRIBE} from "~/utils/constants";

const router = useRouter()
const route = useRoute()

/** emits **/
const emits = defineEmits(["close", "success"])

/** props **/
const props = defineProps({
  title: {
    required: true,
    default: ""
  },
  subtitle: {
    required: true,
    default: ""
  },
  desc: {
    required: true,
    default: ""
  },
  pic: {
    required: true,
    default: {}
  },
  btnText: {
    required: true,
    default: ""
  }
})

/** data **/
const tips = ref("<span>You have been invited to use BNTO by your friend.</span>")
const code = ref(route.query.invitationCode)
const codeErrorTips = ref("")
const codeError = ref(false)
const codeSuccessTips = ref("Invitation Code is Valid")
const codeSuccess = ref(false)

/** computed **/
const isMobile = computed(() => useBaseStore().getterIsMobile)
const userInfo = computed(() => useBaseStore().getterUserInfo)
const isLogin = computed(() => useBaseStore().getterIsLogin)

/** watch **/
watch(code, async () => {
  if (code.value.length === 6) {
    try {
      // 录入正确
      const res = await pointReferralCodeCheck({invitationCode: code.value})
      tips.value = `<span>You have been invited to use BNTO by <strong>${res.result.userName} (${res.result.email})</strong>.</span>`
      codeSuccess.value = true
      codeError.value = false
    } catch (e) {
      // 录入错误
      tips.value = `<span>You have been invited to use BNTO by your friend.</span>`
      codeSuccess.value = false
      codeError.value = true
      codeErrorTips.value = e.message.content
    }
  } else {
    // 未录入
    tips.value = `<span>You have been invited to use BNTO by your friend.</span>`
    codeSuccess.value = false
    codeError.value = false
  }
}, {immediate: true})

/** methods **/
const handleCloseClick = () => {
  emits("close")
}
const handleConfirm = () => {
  if (codeError.value) return
  if (codeSuccess.value) {
    useSubscribeInfoStore().updateInvitationCode(code.value)
    setTimeout(() => {
      if (isLogin.value) {
        router.push(PATH_SUBSCRIBE)
      } else {
        router.push(PATH_REGISTER)
      }
    }, 10)
    emits("success", code.value)
  }
}

/** lifecycle **/

</script>

<template>
  <div class="module-streamer-invitation-mobile" v-if="isMobile">
    <div class="button-close" @click="handleCloseClick">
      {{ $t("close") }}
      <img class="close" src="@/assets/img/icon-close-black.svg" alt="close">
    </div>

    <div class="top">
      <img :src="pic.url" alt="img">
    </div>
    <div class="bottom">
      <div class="subtitle">{{title}}</div>
      <div class="title">
        {{subtitle}}
      </div>
      <div class="tips" v-html="tips"/>
      <div class="code-input">
        <module-input
          :placeholder="'Invitation code'"
          :container-class="'input-container'"
          :input-class="'input'"
          :size="'large'"
          v-model="code"
          :success-tips="codeSuccessTips"
          :success="codeSuccess"
          :error-tips="codeErrorTips"
          :error="codeError"
        />
        <div class="btn-primary-black" :class="{'btn-disabled-black': codeError || !codeSuccess}" @click="handleConfirm"><span>{{btnText}}</span></div>
      </div>
    </div>
  </div>
  <div class="module-streamer-invitation-desktop" v-else>
    <div class="button-close" @click="handleCloseClick">
      {{ $t("close") }}
      <img class="close" src="@/assets/img/icon-close-black.svg" alt="close">
    </div>

    <div class="left">
      <img :src="pic.url" alt="img">
    </div>
    <div class="right">
      <div class="subtitle">{{title}}</div>
      <div class="title">
        {{subtitle}}
      </div>
      <div class="tips" v-html="tips"/>
      <div class="code-input">
        <module-input
          :placeholder="'Invitation code'"
          :container-class="'input-container'"
          :input-class="'input'"
          :size="'large'"
          v-model="code"
          :success-tips="codeSuccessTips"
          :success="codeSuccess"
          :error-tips="codeErrorTips"
          :error="codeError"
        />
        <div class="btn-primary-black" :class="{'btn-disabled-black': codeError || !codeSuccess}" @click="handleConfirm"><span>{{btnText}}</span></div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.module-streamer-invitation-mobile {
  .input {
    background-color: white;
  }
}

.module-streamer-invitation-desktop {
  .input {
    background-color: white;
  }
}
</style>

<style scoped lang="scss">
@import "src/assets/config";

.module-streamer-invitation-mobile {
  padding: 20px 20px 40px 20px;
  background: linear-gradient(180deg, #FFF 0%, #F1E8E4 100%);
  width: 100%;
  position: relative;

  display: flex;
  flex-direction: column;
  gap: 20px;

  .button-close {
    position: absolute;
    top: 20px;
    right: 20px;

    font-family: Druk Wide Cy;
    font-size: 12px;
    font-weight: 500;
    line-height: 10px;
    text-align: center;
    text-decoration: underline;

    display: flex;
    align-items: center;

    cursor: pointer;

    .close {
      width: 20px;
      height: 20px;
      margin-left: 4px;
    }
  }

  .top {
    margin-top: 30px;

    img {
      width: 100%;
      object-fit: contain;
      object-position: center center;
    }
  }

  .bottom {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .subtitle {
      /* Eyebrow/small */
      font-family: "TWK Lausanne";
      font-size: 10px;
      font-style: normal;
      font-weight: 650;
      line-height: 12px; /* 120% */
      text-transform: uppercase;
    }

    .title {
      font-family: "Alternate Gothic No3 D";
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px; /* 93.75% */
      text-transform: uppercase;
      padding-top: 8px;
    }

    .tips {
      height: 36px;

      /* Text sm/Regular */
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px; /* 150% */
      margin-top: 8px;
    }

    .code-input {
      width: 100%;
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      background-color: transparent;

      .input-container {
        width: 100%;
        background-color: transparent;
        height: 66px;
      }

      .btn-primary-black {
        width: 100%;
        padding: 0;
        height: 42px;
        min-height: 0;
      }
    }
  }
}

.module-streamer-invitation-desktop {
  padding: 20px 80px 30px 80px;
  background: linear-gradient(180deg, #FFF 0%, #F1E8E4 100%);
  min-width: $content-1280-width;
  width: 100%;
  position: relative;

  display: flex;
  justify-content: center;
  gap: 50px;

  .button-close {
    position: absolute;
    top: 30px;
    right: 30px;

    font-family: Druk Wide Cy;
    font-size: 12px;
    font-weight: 500;
    line-height: 10px;
    text-align: center;
    text-decoration: underline;

    display: flex;
    align-items: center;

    cursor: pointer;

    .close {
      width: 20px;
      height: 20px;
      margin-left: 4px;
    }
  }

  .left {
    padding-top: 30px;

    img {
      width: calc((100vw - 80px - 80px - 50px) / 2);
      object-fit: contain;
      object-position: center center;
    }
  }

  .right {
    width: calc((100vw - 80px - 80px - 50px) / 2);
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .subtitle {
      /* Eyebrow/small */
      font-family: "TWK Lausanne";
      font-size: 10px;
      font-style: normal;
      font-weight: 650;
      line-height: 12px; /* 120% */
      text-transform: uppercase;
    }

    .title {
      width: 550px;
      font-family: "Alternate Gothic No3 D";
      font-size: 46px;
      font-style: normal;
      font-weight: 400;
      line-height: 44px; /* 95.652% */
      text-transform: uppercase;
      padding-top: 8px;
    }

    .tips {
      /* Text sm/Regular */
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px; /* 150% */
    }

    .code-input {
      flex-shrink: 0;
      margin-top: 60px;
      display: flex;
      gap: 8px;
      background-color: transparent;

      .input-container {
        background-color: transparent;
        height: fit-content;
      }

      .btn-primary-black {
        width: 550px;
        padding: 0;
        height: 42px;
        min-height: 0;
      }
    }
  }
}
</style>
