import {omniFetch} from "~/utils/fetch";
import {config} from "~/utils/config";

export function waitlistSignup({email}) {
  return omniFetch({
    url: config.v1Path + config.waitlistSignupPath,
    method: "post",
    params: {email}
  })
}
