<script setup>
import {useBaseStore} from "~/stores/base";

/** computed **/
const isMobile = computed(() => useBaseStore().getterIsMobile)
</script>

<template>
  <div class="home-skeleton-mobile" v-if="isMobile">
    <el-skeleton animated>
      <template #template>
        <el-skeleton-item class="banner"/>
      </template>
    </el-skeleton>
    <div class="loading-content">
      <br>
      <el-skeleton :rows="3" animated/>
      <br>
      <el-skeleton :rows="5" animated/>
      <br>
      <el-skeleton :rows="5" animated/>
    </div>
  </div>
  <div class="home-skeleton-desktop" v-else>
    <el-skeleton animated>
      <template #template>
        <el-skeleton-item class="banner"/>
      </template>
    </el-skeleton>
    <div class="loading-content">
      <br>
      <el-skeleton :rows="3" animated/>
      <br>
      <el-skeleton :rows="5" animated/>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "src/assets/config";

.home-skeleton-mobile {
  padding: 16px;

  .banner {
    width: calc(100vw - 32px);
    height: calc(34vw - 32px)
  }
}

.home-skeleton-desktop {
  .banner {
    width: 100%;
    min-width: $content-1280-width;
    height: 34vw
  }

  .loading-content {
    width: $content-1280-width;
    margin: 0 auto;
  }
}
</style>
