<script setup>
import {useBaseStore} from "~/stores/base";
import ModuleInput from "~/components/ModuleInput.vue";
import {EMAIL_ERROR_TIPS_MAP} from "~/utils/constants";
import {emailValid} from "~/utils/common";
import {couponSend} from "~/api/api.discount";

const emits = defineEmits(["close", "success"])

/** props **/
const props = defineProps({
  title: {
    required: true,
    default: ""
  },
  subtitle: {
    required: true,
    default: ""
  },
  tips: {
    required: true,
    default: ""
  }
})

/** data **/
const isShowSuccess = ref(false)
const email = ref("")
const emailErrorTips = ref("")
const emailError = ref(false)
const isLoading = ref(false)

/** computed **/
const isMobile = computed(() => useBaseStore().getterIsMobile)
const appInit = computed(() => useBaseStore().getterAppInit)

/** methods **/
const handleCloseClick = () => {
  emits("close")
}
const handleEmailBlur = () => {
  // reset
  isShowSuccess.value = false
  emailError.value = false
  emailErrorTips.value = ""
  // 必填校验
  if (!email.value) {
    emailError.value = true
    emailErrorTips.value = EMAIL_ERROR_TIPS_MAP.REQUIRED
    return
  }
  // 合法校验
  const valid = emailValid(email.value)
  if (!valid) {
    emailError.value = true
    emailErrorTips.value = EMAIL_ERROR_TIPS_MAP.FORMAT_ERROR
  }
}
const handleConfirm = async () => {
  if (!email.value) return;
  if (emailError.value) return;
  if (isLoading.value) return;
  try {
    isLoading.value = true
    await couponSend({couponActivityId: "1", email: email.value})
    isLoading.value = false
    isShowSuccess.value = true
    emits("success")
  } catch (e) {
    isLoading.value = false
    isShowSuccess.value = false
    emailError.value = true
    emailErrorTips.value = e.message.content
  }
}
</script>

<template>
  <div class="module-streamer-coupon-mobile" v-if="isMobile">
    <div class="button-close" @click="handleCloseClick">
      {{ $t("close") }}
      <img class="close" src="@/assets/img/icon-close.svg" alt="close">
    </div>
    <div class="welcome">
      <span class="top">{{ $t('streamerWelcome') }}</span>
      <span class="center">{{ title }}</span>
      <span class="bottom">{{ subtitle }}</span>
    </div>
    <div class="input-container-top">
      <module-input
        type="text"
        placeholder="Email address"
        v-model="email"
        name="email"
        :input-class="'streamer-coupon-input'"
        :error-tips="emailErrorTips"
        :error="emailError"
        @blur="handleEmailBlur"
      />
      <div
        class="btn-secondary button-get-a-discount"
        :class="{'btn-disabled-secondary': isLoading}"
        @click="handleConfirm"
      >
        <img class="flash" src="@/assets/img/icon-flash.svg" alt="flash" :style="{opacity: isLoading ? 0.4 : 1}">
        {{ $t('streamerGetADiscount') }}
      </div>
    </div>
    <div class="input-container-bottom" v-if="isShowSuccess">
      {{ $t("streamerThankYou") }}
    </div>
    <div class="tips">
        {{ tips }}
        <nuxt-link :to="appInit.agreementLink.privacyPolicyLink">{{ $t("streamerRightTipsPrivacyPolicy") }}</nuxt-link>
        {{ $t("streamerRightTipsEnd") }}
      </div>
  </div>
  <div class="module-streamer-coupon-desktop" v-else>
    <div class="module-streamer-coupon-desktop-left">
      <div class="welcome">
        <span class="top">{{ $t('streamerWelcome') }}</span>
        <span class="center">{{ title }}</span>
        <span class="bottom">{{ subtitle }}</span>
      </div>
    </div>
    <div class="module-streamer-coupon-desktop-right">
      <div class="input-container">
        <div class="input-container-top">
          <module-input
            type="text"
            placeholder="Email address"
            v-model="email"
            name="email"
            :input-class="'streamer-coupon-input'"
            :error-tips="emailErrorTips"
            :error="emailError"
            @blur="handleEmailBlur"
          />
          <div
            class="btn-secondary button-get-a-discount"
            :class="{'btn-disabled-secondary': isLoading}"
            @click="handleConfirm"
          >
            <img class="flash" src="@/assets/img/icon-flash.svg" alt="flash" :style="{opacity: isLoading ? 0.4 : 1}">
            {{ $t('streamerGetADiscount') }}
          </div>
        </div>
        <div class="input-container-bottom" v-if="isShowSuccess">
          {{ $t("streamerThankYou") }}
        </div>
      </div>
      <div class="tips">
        {{ tips }}
        <nuxt-link :to="appInit.agreementLink.privacyPolicyLink">{{
            $t("streamerRightTipsPrivacyPolicy")
          }}
        </nuxt-link>
        {{ $t("streamerRightTipsEnd") }}
      </div>
    </div>
    <div class="button-close" @click="handleCloseClick">
      {{ $t("close") }}
      <img class="close" src="@/assets/img/icon-close.svg" alt="close">
    </div>
  </div>
</template>

<style lang="scss">
@import "src/assets/config";

.streamer-coupon-input {
  height: 42px;
  background-color: white;
}
</style>

<style scoped lang="scss">
@import "src/assets/config";

.module-streamer-coupon-mobile {
  width: 100%;
  background-color: $color-gray-800;
  color: white;

  border-top: .1px white solid;

  display: flex;
  flex-direction: column;
  padding: 16px;
  position: relative;

  .button-close {
    cursor: pointer;
    align-self: flex-end;

    font-family: Druk Wide Cy;
    font-size: 12px;
    font-weight: 500;
    line-height: 10px;
    text-align: center;
    text-decoration: underline;

    display: flex;
    align-items: center;

    .close {
      width: 20px;
      height: 20px;
      margin-left: 4px;
    }
  }

  .welcome {
    display: flex;
    flex-direction: column;
    margin-top: 24px;

    .top {
      //styleName: Eyebrow/small;
      font-family: "TWK Lausanne";
      font-weight: 650;
      font-size: 10px;
      line-height: 12px;
      text-align: left;
    }

    .center {
      /* Headline/small */
      font-family: "Alternate Gothic No3 D";
      font-size: 36px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px; /* 88.889% */
      text-transform: uppercase;

      margin-top: 8px;
    }

    .bottom {
      font-family: TWK Lausanne;
      font-size: 14px;
      font-weight: 300;
      line-height: 20px;
      text-align: left;

      margin-top: 3px;
    }
  }

  .input-container-top {
    margin-top: 24px;
    display: flex;
    flex-direction: column;

    input {
      width: 100%;
      padding: 9px 12px;

      //styleName: Text md/Regular;
      font-family: TWK Lausanne;
      font-size: 16px;
      font-weight: 300;
      line-height: 24px;
      text-align: left;
      color: $color-gray-800;
    }

    input::placeholder {
      color: #737373;
    }

    .button-get-a-discount {
      width: 100%;
      height: 42px;
      min-height: 42px;

      //styleName: Button/large;
      font-family: Druk Wide Cy;
      font-size: 12px;
      font-weight: 500;
      line-height: 10px;
      text-align: center;
      background-color: white;
      color: $color-gray-800;

      margin-top: 8px;

      display: flex;
      justify-content: center;
      align-items: center;

      padding: 12px 0 10px 0;

      .flash {
        margin-right: 4px;
        width: 20px;
        height: 20px;
      }
    }
  }

  .input-container-bottom {
    font-family: TWK Lausanne;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;

    margin-top: 8px;
    color: #FCFCFC;
  }

  .tips {
    font-family: TWK Lausanne;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;

    margin-top: 8px;
    color: #A3A3A3;

    a {
      font-family: "TWK Lausanne";
      font-weight: 650;
      cursor: pointer;
    }
  }
}

.module-streamer-coupon-desktop {
  min-width: $content-1280-width;
  width: 100%;
  background-color: $color-gray-800;
  color: white;

  border-top: .1px white solid;

  padding: 24px 153px 24.5px 30px;
  position: relative;

  display: flex;
  justify-content: space-between;
  align-items: center;

  .module-streamer-coupon-desktop-left {
    display: flex;
    align-items: center;

    .welcome {
      display: flex;
      flex-direction: column;

      .top {
        //styleName: Eyebrow/small;
        font-family: "TWK Lausanne";
        font-weight: 650;
        font-size: 10px;
        line-height: 12px;
        text-align: left;
      }

      .center {
        /* Headline/small */
        font-family: "Alternate Gothic No3 D";
        font-size: 36px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px; /* 88.889% */
        text-transform: uppercase;

        margin-top: 8px;
      }

      .bottom {
        font-family: TWK Lausanne;
        font-size: 14px;
        font-weight: 300;
        line-height: 20px;
        text-align: left;

        margin-top: 3px;
      }
    }
  }

  .module-streamer-coupon-desktop-right {
    width: 563px;

    .input-container {
      .input-container-top {
        display: flex;

        .button-get-a-discount {
          height: 42px;
          min-height: 42px;
          flex-shrink: 0;
          margin-left: 8px;
          padding-left: 32px;
          padding-right: 32px;

          .flash {
            margin-right: 4px;
            width: 20px;
            height: 20px;
          }
        }
      }

      .input-container-bottom {
        font-family: TWK Lausanne;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        text-align: left;

        margin-top: 8px;
        color: #FCFCFC;
      }
    }

    .tips {
      font-family: TWK Lausanne;
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      text-align: left;

      margin-top: 8px;
      color: #A3A3A3;

      a {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .button-close {
    position: absolute;
    right: 30px;
    top: 24px;

    font-family: Druk Wide Cy;
    font-size: 12px;
    font-weight: 500;
    line-height: 10px;
    text-align: center;
    text-decoration: underline;

    display: flex;
    align-items: center;

    cursor: pointer;

    .close {
      width: 20px;
      height: 20px;
      margin-left: 4px;
    }
  }
}
</style>
