import {config} from "~/utils/config.js";
import {omniFetch} from "~/utils/fetch.js";

export function couponSend({couponActivityId, email}) {
  return omniFetch({
    url: config.v1Path + config.couponSendPath,
    method: "post",
    params: {
      couponActivityId,
      email
    }
  })
}
